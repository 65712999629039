<template>
  <div>
    <div id="pageRegistUser" class="mainContents">
      <section class="titleArea">
        <h2>会員登録</h2>
        <!-- /.titleArea -->
      </section>

      <form>
        <div class="registProfileArea">
          <div class="inner">
            <div class="flow">
              <ul>
                <li class="active">
                  情報登録
                </li>
                <li>登録内容の確認</li>
                <li>登録完了</li>
              </ul>
            </div>
            <p
              v-for="(error, index) in errors"
              :key="index"
              class="errorMssage"
            >
              {{ error }}
            </p>
            <div class="group">
              <dl class="name">
                <div>
                  <dt>姓</dt>
                  <dd class="inputWrap" :class="{ error: !validLastname }">
                    <input
                      ref="lastname"
                      v-model="userForm.lastname"
                      :disabled="isUploading"
                      type="text"
                      placeholder="山田"
                    >
                  </dd>
                </div>
                <div>
                  <dt>名</dt>
                  <dd class="inputWrap" :class="{ error: !validFirstname }">
                    <input
                      ref="firstname"
                      v-model="userForm.firstname"
                      :disabled="isUploading"
                      type="text"
                      placeholder="太郎"
                    >
                  </dd>
                </div>
                <div>
                  <dt>姓（カタカナ）</dt>
                  <dd class="inputWrap" :class="{ error: !validLastnameKana }">
                    <input
                      ref="lastname_kana"
                      v-model="userForm.lastname_kana"
                      :disabled="isUploading"
                      type="text"
                      placeholder="ヤマダ"
                    >
                  </dd>
                </div>
                <div>
                  <dt>名（カタカナ）</dt>
                  <dd class="inputWrap" :class="{ error: !validFirstnameKana }">
                    <input
                      ref="firstname_kana"
                      v-model="userForm.firstname_kana"
                      :disabled="isUploading"
                      type="text"
                      placeholder="タロウ"
                    >
                  </dd>
                </div>
              </dl>
              <ul class="gender" :class="{ error: !validGender }">
                <li>
                  <label><input
                    ref="gender"
                    v-model="userForm.gender"
                    type="radio"
                    value="male"
                    checked="checked"
                  ><span>男</span></label>
                </li>
                <li>
                  <label><input
                    ref="gender"
                    v-model="userForm.gender"
                    type="radio"
                    value="female"
                  ><span>女</span></label>
                </li>
              </ul>
            </div>
            <div class="group">
              <dl class="birthday">
                <dt>生年月日</dt>
                <dd>
                  <div
                    class="selectWrap year"
                    :class="{ error: !validBirthdayYear }"
                  >
                    <select ref="birthdayYear" v-model="userForm.birthday.year">
                      <option
                        v-for="(year, index) in years"
                        :key="index"
                        :value="year"
                      >
                        {{ year }}
                      </option>
                    </select>
                  </div>
                  <span>年</span>
                </dd>
                <dd>
                  <div
                    class="selectWrap"
                    :class="{ error: !validBirthdayMonth }"
                  >
                    <select
                      ref="birthdayMonth"
                      v-model="userForm.birthday.month"
                    >
                      <option
                        v-for="(month, index) in months"
                        :key="index"
                        :value="month"
                      >
                        {{ month }}
                      </option>
                    </select>
                  </div>
                  <span>月</span>
                </dd>
                <dd>
                  <div class="selectWrap" :class="{ error: !validBirthdayDay }">
                    <select ref="birthdayDay" v-model="userForm.birthday.day">
                      <option
                        v-for="(day, index) in days"
                        :key="index"
                        :value="day"
                      >
                        {{ day }}
                      </option>
                    </select>
                  </div>
                  <span>日</span>
                </dd>
              </dl>
            </div>
            <div class="group">
              <dl class="prefectures">
                <dt>お住まいの都道府県</dt>
                <dd>
                  <div
                    class="selectPrefectures"
                    :class="{ error: !validPrefecture }"
                  >
                    <select ref="prefecture" v-model="userForm.prefecture">
                      <option
                        v-for="(pref, index) in prefectures"
                        :key="index"
                        :value="pref"
                      >
                        {{ pref }}
                      </option>
                    </select>
                  </div>
                </dd>
              </dl>
            </div>
            <div class="group">
              <dl class="mail">
                <div>
                  <dt>メールアドレス</dt>
                  <dd class="inputWrap">
                    <input
                      :value="user.email"
                      type="text"
                      readonly="readonly"
                    >
                  </dd>
                </div>
              </dl>
            </div>
            <div class="group">
              <dl class="password">
                <div>
                  <dt>
                    パスワード<span class="rule">※8文字以上16文字以内</span>
                  </dt>
                  <dd class="inputWrap" :class="{ error: !validPassword }">
                    <input
                      ref="password"
                      v-model="userForm.password"
                      :disabled="isUploading"
                      type="password"
                      placeholder="＊＊＊＊＊＊＊＊"
                    >
                  </dd>
                </div>
              </dl>
            </div>
            <div class="terms">
              <p>
                ご登録前に「<a
                  href="/html/terms"
                  target="_blank"
                  rel="noopener"
                >利用規約</a>」をお読みいただけてから、会員登録をしてください。
              </p>
              <p class="consent">
                <label><input
                  ref="terms"
                  v-model="userForm.terms"
                  type="checkbox"
                ><span>規約に同意します</span></label>
              </p>
            </div>

            <div class="buttonArea">
              <p class="submit">
                <button
                  :disabled="formInvalid"
                  type="submit"
                  name="button"
                  @click="create($event)"
                >
                  登録内容を確認する
                </button>
              </p>
              <!-- /.buttonArea -->
            </div>
          </div>
          <!-- /.registProfileArea -->
        </div>
        <input :value="user.email" type="hidden" name="email">
        <input :value="user.key" type="hidden" name="key">
      </form>

      <!-- /.mainContents -->
    </div>
  </div>
</template>

<script>
import {
  fieldNotNull,
  validEmail,
  isZenKatakana,
  validDate,
  greaterThan,
  lessThan
} from '@/helpers/validator.js'

export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isUploading: false,
      userForm: {
        lastname: '',
        firstname: '',
        lastname_kana: '',
        firstname_kana: '',
        gender: '',
        birthday: {
          year: '',
          month: '',
          day: ''
        },
        prefecture: '',
        password: '',
        terms: ''
      },
      errors: {}
    }
  },
  computed: {
    validLastname: function () {
      return fieldNotNull(this.userForm.lastname)
    },
    validFirstname: function () {
      return fieldNotNull(this.userForm.firstname)
    },
    validLastnameKana: function () {
      return (
        fieldNotNull(this.userForm.lastname_kana) &&
        isZenKatakana(this.userForm.lastname_kana)
      )
    },
    validFirstnameKana: function () {
      return (
        fieldNotNull(this.userForm.firstname_kana) &&
        isZenKatakana(this.userForm.firstname_kana)
      )
    },
    validGender: function () {
      return fieldNotNull(this.userForm.gender)
    },
    validBirthdayYear: function () {
      return fieldNotNull(this.userForm.birthday.year)
    },
    validBirthdayMonth: function () {
      return fieldNotNull(this.userForm.birthday.month)
    },
    validBirthdayDay: function () {
      return (
        fieldNotNull(this.userForm.birthday.day) &&
        validDate(
          this.userForm.birthday.year,
          this.userForm.birthday.month,
          this.userForm.birthday.day
        )
      )
    },
    validBirthday: function () {
      return validDate(
        this.userForm.birthday.year,
        this.userForm.birthday.month,
        this.userForm.birthday.day
      )
    },
    validBirthdayDisplay: function () {
      return fieldNotNull(this.userForm.birthday_display)
    },
    validPrefecture: function () {
      return fieldNotNull(this.userForm.prefecture)
    },
    validEmail: function () {
      return fieldNotNull(this.user.email) && validEmail(this.user.email)
    },
    validPassword: function () {
      return (
        fieldNotNull(this.userForm.password) &&
        greaterThan(this.userForm.password, 8) &&
        lessThan(this.userForm.password, 16)
      )
    },
    formInvalid: function () {
      if (this.isUploading) {
        return true
      }

      if (!this.validLastname) {
        return true
      }

      if (!this.validFirstname) {
        return true
      }

      if (!this.validLastnameKana) {
        return true
      }

      if (!this.validFirstnameKana) {
        return true
      }

      if (!this.validGender) {
        return true
      }

      if (!this.validBirthday) {
        return true
      }

      if (!this.validPrefecture) {
        return true
      }

      if (!this.validEmail) {
        return true
      }

      if (!this.validPassword) {
        return true
      }

      if (!this.userForm.terms) {
        return true
      }

      return false
    },
    years: function () {
      var years = {}
      for (let i = 1920; i <= 2020; i++) {
        years[i] = i
      }
      return years
    },
    months: function () {
      var months = {}
      for (let i = 1; i <= 12; i++) {
        months[i] = i
      }
      return months
    },
    days: function () {
      var days = {}
      for (let i = 1; i <= 31; i++) {
        days[i] = i
      }
      return days
    },
    prefectures: function () {
      return {
        1: '北海道',
        2: '青森県',
        3: '岩手県',
        4: '宮城県',
        5: '秋田県',
        6: '山形県',
        7: '福島県',
        8: '茨城県',
        9: '栃木県',
        10: '群馬県',
        11: '埼玉県',
        12: '千葉県',
        13: '東京都',
        14: '神奈川県',
        15: '新潟県',
        16: '富山県',
        17: '石川県',
        18: '福井県',
        19: '山梨県',
        20: '長野県',
        21: '岐阜県',
        22: '静岡県',
        23: '愛知県',
        24: '三重県',
        25: '滋賀県',
        26: '京都府',
        27: '大阪府',
        28: '兵庫県',
        29: '奈良県',
        30: '和歌山県',
        31: '鳥取県',
        32: '島根県',
        33: '岡山県',
        34: '広島県',
        35: '山口県',
        36: '徳島県',
        37: '香川県',
        38: '愛媛県',
        39: '高知県',
        40: '福岡県',
        41: '佐賀県',
        42: '長崎県',
        43: '熊本県',
        44: '大分県',
        45: '宮崎県',
        46: '鹿児島県',
        47: '沖縄県'
      }
    }
  },
  methods: {
    create () {
      event.preventDefault()

      this.isUploading = true

      this.$store
        .dispatch('confirmUser', {
          email: this.user.email,
          key: this.user.key,
          lastname: this.userForm.lastname,
          firstname: this.userForm.firstname,
          lastname_kana: this.userForm.lastname_kana,
          firstname_kana: this.userForm.firstname_kana,
          gender: this.userForm.gender,
          birthday: {
            year: this.userForm.birthday.year,
            month: this.userForm.birthday.month,
            day: this.userForm.birthday.day
          },
          prefecture: this.userForm.prefecture,
          password: this.userForm.password,
          terms: this.userForm.terms
        })
        .then(() => {
          this.$router.push({ name: 'confirm' })
        })
        .catch(err => {
          this.isUploading = false
          this.errors.response = err.response.data
        })
    }
  }
}
</script>

<style scoped></style>
