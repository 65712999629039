export function fieldNotNull (str) {
  if (str === undefined) {
    return false
  }

  if (str === null) {
    return false
  }

  if (str === '') {
    return false
  }

  return true
}

export function fieldsEqual (str1, str2) {
  return str1 === str2
}

export function greaterThan (str, len) {
  return str.length && str.length >= len
}

export function lessThan (str, len) {
  return str.length && str.length <= len
}

export function validEmail (str) {
  if (!str) {
    return true
  }

  if (str === '') {
    return true
  }

  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(str) // eslint-disable-line
}

export function isZenKatakana (str) {
  str = (str === null) ? '' : str
  if (str.match(/^[ァ-ヶー　]+$/)) { // eslint-disable-line
    return true
  } else {
    return false
  }
}

export function validDate (year, month, day) {
  if (!year || !month || !day) {
    return false
  }
  var d = new Date(
    year,
    month - 1,
    day
  )
  if (!d || d.getMonth() + 1 !== parseInt(month)) {
    return false
  } else {
    return true
  }
}
